<template>
  <form @submit.prevent="post" class="page_container">
    <section class="row center">
      <div class="pt-35 pb-5 w600 row space-between">
        <h1 class="my-0">Customer billing</h1>
        <button v-if="!editMode" @click="editBilling" class="center gap-10">
          <s-icon color="white" height="16">pencil-outline</s-icon>
          <span class="font-14 opacity-75 weight-300">Edit billing information</span>
        </button>
        <div class="center gap-10" v-if="editMode">
          <s-btn @click.native="closeBilling"> Close </s-btn>
          <s-btn @click.native="editBilling" class="green"> Save </s-btn>
        </div>
      </div>
    </section>
    <hr class="mt-15" />

    <section class="center pt-50 pb-20 col gap-20">
      <div class="grid align-center mt-20">
        <div class="font-14 opacity-60">Company Name</div>
        <s-text-field   :noedit="editMode" v-model="companyName" height="30"></s-text-field>
        <div class="font-14 opacity-60">Contact Email</div>
        <s-text-field   :noedit="editMode" v-model="emailContact" height="30"></s-text-field>
        <div class="font-14 opacity-60">Billing Email</div>
        <s-text-field   :noedit="editMode" v-model="emailBilling" height="30"></s-text-field>
      </div>
    </section>

    <section class="center py-20 col gap-20">
      <div class="w600">Contact Address</div>
      <div class="grid align-center mt-20">
        <div class="font-14 opacity-60">Address 1</div>
        <s-text-field   :noedit="editMode" v-model="contactAddress1" height="30"></s-text-field>
        <div class="font-14 opacity-60">Address 2</div>
        <s-text-field   :noedit="editMode" v-model="contactAddress2" height="30"></s-text-field>
        <div class="font-14 opacity-60">City</div>
        <s-text-field  :noedit="editMode" v-model="contactCity" height="30"></s-text-field>
        <div class="font-14 opacity-60">State</div>
        <s-text-field  :noedit="editMode" v-model="contactState" height="30"></s-text-field>
        <div class="font-14 opacity-60">Country</div>
        <s-dropdown
        :noedit="!editMode"
          key="2"
          placeholder="Country"
          :countries="countries"
          v-model="contactCountry"
        ></s-dropdown>
        <div class="font-14 opacity-60">Post code</div>
        <s-text-field  :noedit="editMode" v-model="contactZIP" height="30"></s-text-field>
      </div>
    </section>

    <section class="center py-20 col gap-20">
      <div class="w600">Billing Address</div>
      <div class="grid align-center mt-20">
        <div class="font-14 opacity-60">Address 1</div>
        <s-text-field  :noedit="editMode" v-model="billingAddress1" height="30"></s-text-field>
        <div class="font-14 opacity-60">Address 2</div>
        <s-text-field :noedit="editMode"  v-model="billingAddress2" height="30"></s-text-field>
        <div class="font-14 opacity-60">City</div>
        <s-text-field :noedit="editMode" v-model="billingCity" height="30"></s-text-field>
        <div class="font-14 opacity-60">State</div>
        <s-text-field :noedit="editMode" v-model="billingState" height="30"></s-text-field>
        <div class="font-14 opacity-60">Country</div>
        <s-dropdown
        :noedit="!editMode"
          key="1"
          placeholder="Country"
          :countries="countries"
          v-model="billingCountry"
        ></s-dropdown>
        <div class="font-14 opacity-60">Post code</div>
        <s-text-field :noedit="editMode" v-model="billingZIP" height="30"></s-text-field>
      </div>
    </section>

    <section class="center py-20 col gap-20">
      <div class="w600">Tax Information</div>
      <div class="grid align-center mt-20">
        <div class="font-14 opacity-60">Company Identification Number</div>
        <s-text-field
        :noedit="editMode"
          v-model="companyIdentificationNumber"
          height="30"
        ></s-text-field>
        <div class="font-14 opacity-60">US Tax ID / FEIN</div>
        <s-text-field  :noedit="editMode" v-model="taxNumber" height="30"></s-text-field>
        <div class="font-14 opacity-60">EU Tax ID</div>
        <s-text-field  :noedit="editMode" v-model="taxEUNumber" height="30"></s-text-field>
      </div>
    </section>
  </form>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      companyName: "",
      emailContact: "",
      emailBilling: "",
      contactAddress1: "",
      contactAddress2: "",
      contactZIP: "",
      contactCity: "",
      contactState: "",
      contactCountry: "",
      billingAddress1: "",
      billingAddress2: "",
      billingZIP: "",
      billingCity: "",
      billingState: "",
      billingCountry: "",
      companyIdentificationNumber: "",
      taxNumber: "",
      taxEUNumber: "",
      editMode: false,
    };
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    async post() {
      let data = {
        billingInfo: {
          companyName: this.companyName,
          emailContact: this.emailContact,
          emailBilling: this.emailBilling,
          contactAddress1: this.contactAddress1,
          contactAddress2: this.contactAddress2,
          contactZIP: this.contactZIP,
          contactCity: this.contactCity,
          contactState: this.contactState,
          contactCountry: this.contactCountry,
          billingAddress1: this.billingAddress1,
          billingAddress2: this.billingAddress2,
          billingZIP: this.billingZIP,
          billingCity: this.billingCity,
          billingState: this.billingState,
          billingCountry: this.billingCountry,
          companyIdentificationNumber: this.companyIdentificationNumber,
          taxNumber: this.taxNumber,
          taxEUNumber: this.taxEUNumber,
        },
      };
      let res = await this.$store.dispatch("user/postCompany", data);
      this.$store.dispatch("user/getUserInformation");
    },
    editBilling() {
      this.editMode = !this.editMode;
      if (!this.editMode) this.post();
    },
    closeBilling() {
      this.firstNameLocal = this.firstName;
      this.lastNameLocal = this.lastName;
      this.editMode = !this.editMode;
    },
  },
  computed: {
    ...mapState("user", ["billingInfo", "countries"]),
  },
  mounted() {
    this.$store.dispatch("user/getUserInformation");
    this.$store.dispatch("user/getCountries");
    this.companyName = this.billingInfo.companyName;
    this.emailBilling = this.billingInfo.emailBilling;
    this.emailContact = this.billingInfo.emailContact;
    this.contactAddress1 = this.billingInfo.contactAddress1;
    this.contactAddress2 = this.billingInfo.contactAddress2;
    this.contactZIP = this.billingInfo.contactZIP;
    this.contactCity = this.billingInfo.contactCity;
    this.contactState = this.billingInfo.contactState;
    this.contactCountry = this.billingInfo.contactCountry;
    this.billingAddress1 = this.billingInfo.billingAddress1;
    this.billingAddress2 = this.billingInfo.billingAddress2;
    this.billingCity = this.billingInfo.billingCity;
    this.billingState = this.billingInfo.billingState;
    this.billingCountry = this.billingInfo.billingCountry;
    this.billingZIP = this.billingInfo.billingZIP;
    this.companyIdentificationNumber =
      this.billingInfo.companyIdentificationNumber;
    this.taxNumber = this.billingInfo.taxNumber;
    this.taxEUNumber = this.billingInfo.taxEUNumber;
  },
};
</script>

<style lang="less" scoped>
.grid {
  display: grid;
  grid-template-columns: 150px auto;
  grid-row-gap: 15px;
  grid-column-gap: 15px;
  max-width: 600px;
  width: 100%;
}

.profile {
  background-color: grey;
  height: 100px;
  width: 100px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pic {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.camera {
  position: absolute;
  right: 0px;
  bottom: 4px;
  padding: 2px;
  transition: all 0.2s ease;
}

.camera:hover {
  transform: scale(1.1);
  opacity: 1;
}

.camera:focus {
  background-color: #ffffff;
  transform: scale(1.1);
  opacity: 1;
}
</style>